<section
  class="relative flex flex-col rounded-3xl px-6 sm:px-8 border-pink-400/50 hover:border-pink-400 border-solid border-2 pt-8 pb-10 transition-colors {{
    featured ? 'bg-pink-400' : ''
  }}"
>
  <div *ngIf="popular"
    class="absolute -top-2 left-1/2 -ml-10 w-20 text-center rounded-full px-2.5 py-1 text-sm font-bold leading-5 bg-pink-500 text-pink-900 shadow-xs shadow-pink-950/40">
    Popular
  </div>
  <h3 class="mt-5 font-normal text-3xl text-nowrap {{featured ? 'text-pink-950/80' : 'text-white'}}">
    <ng-icon *ngIf="free" name="heroGift" size="2.5rem" class="align-top"></ng-icon>
    <ng-icon *ngIf="!featured && !topTier && !free" name="heroShieldCheck" size="2.5rem" class="align-top"></ng-icon>
    <ng-icon *ngIf="featured" name="heroHeart" size="2.5rem" class="align-top"></ng-icon>
    <ng-icon *ngIf="topTier" name="heroStar" size="2.5rem" class="align-top"></ng-icon>
    <span class="inline-block align-top ml-3">{{ name }}</span>
  </h3>
  <p class="mt-2 text-base {{ featured ? 'text-pink-950' : 'text-pink-100' }}">
    {{ description }}
  </p>
  <p class="order-first font-light text-4xl tracking-tight text-white">
    {{ price }}
    <span *ngIf="!free" class="text-3xl {{featured ? 'text-pink-900' : 'text-pink-400'}}">
      {{ billingCycle === "yearly" ? "/ year" : "/ month" }}
    </span>
  </p>
  <p *ngIf="cryptoPrice" class="order-first text-lg tracking-tight {{featured ? 'text-pink-900' : 'text-pink-400 font-light'}}">
    {{ cryptoPrice }}
  </p>
  <p class="order-first text-md {{ featured ? 'text-pink-950' : 'text-pink-100' }}">
    {{ free ? "Free forever" : "Billed " + billingCycle }}
  </p>
  <ul
    role="list"
    class="order-last mt-10 flex flex-col gap-y-3 text-sm {{ featured ? 'text-pink-950' : 'text-pink-100' }}"
  >
    <li *ngFor="let feature of features" class="flex">
      <cs-check-icon class="{{ featured ? 'text-pink-800/90' : 'text-pink-400' }}"></cs-check-icon>
      <span class="ml-4"><a routerLink="/help/plans/{{productId}}">{{ feature }}</a></span>
    </li>
  </ul>
  <a
    *ngIf="!hideButton"
    csButton
    [routerLink]="getCallToActionLink() | async"
    [queryParams]="{ billingCycle: billingCycle, countryCode: country, crypto: cryptoSymbol }"
    [variant]="isCurrentPlan() ? 'outline' : 'solid'"
    color="white"
    class="mt-8 w-full"
    [ngClass]="{
      'cursor-default': isCurrentPlan(),
      'cursor-pointer': !isCurrentPlan()
    }"
  >
    {{ getCallToActionText() | async }}
  </a>
</section>
